import { BM_LANG_CONFIG, EN_LANG_CONFIG } from "./lang";
import {BackendResponse} from "./types";
import {CONFIG} from "./config";

const getLabelValue = (language: string = 'en', key: string = '') => {
    if (language === '') return '';
    if (key === '') return '';
    if (language === 'en') return EN_LANG_CONFIG[key];
    if (language === 'bm') return BM_LANG_CONFIG[key]
    return ''
}

const logError = (title: string, error: any): void => {
    if (CONFIG.env === "test")
        console.log(`${title} :: `, error);
}

const callBackendAPI = async (
    route: string = '', method: string, headers: HeadersInit, data: any
): Promise<BackendResponse | undefined> => {
    try {
        if (route === '') return { error: 'Missing route in call'};
        let options: RequestInit = {
            method: method,
        };
        if (headers)
            options['headers'] = headers;

        if (data) {
            options['body'] = JSON.stringify(data);
        }

        let API_URL: string = CONFIG.env === "test" ? CONFIG.apiUrlTest : CONFIG.apiUrlLive;
        let response: Response = await fetch(`${API_URL}/${route}`, options);
        let results: any = await response.json();
        return { error: undefined, results }
    } catch (e: any) {
        logError('Error callBackendAPI', e);
        return { error: e.toString() }
    }
}

const numberWithCommas = (x: number) => {
    if (x === undefined)  return '';
    let parts: string[] = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

const getSignatureFromUrl = (url: string): string => {
    try {
        const parts = url.split('/');
        const signature = parts[parts.length - 1];

        return signature || ''
    } catch (error) {
        logError("Error extracting signature:", error);
        return "";
    }
}

const stripCharactersAndTrim = (value: string, searchValue: string, replaceValue: string): string => {
    if (value === '') return value;
    return value.replace(searchValue, replaceValue).trim();
}

export {
    getLabelValue,
    callBackendAPI,
    logError,
    numberWithCommas,
    getSignatureFromUrl,
    stripCharactersAndTrim
}
